<kendo-grid #grid [kendoGridBinding]="(tsList$ | async) || []" 
    [pageable]="true" [skip]="skip" [pageSize]="250"
    [sortable]="true" [sort]="sort"
    [filterable]="true" [filter]="filter" (filterChange)="filterChanged($event)" 
    [navigable]="true" [reorderable]="true" [resizable]="true" [scrollable]="'scrollable'"
    [groupable]="groupable" [group]="group" kendoGridExpandGroupBy 
    [groupsInitiallyExpanded]="true" [(expandedGroupKeys)]="expandedGroupKeys"
    (groupChange)="groupChange()"
    [selectable]="{enabled: true, checkboxOnly: true, mode: 'multiple'}" [kendoGridSelectBy]="'tsid'"
    [(selectedKeys)]="selectedTsids"
    [kendoGridExpandDetailsBy]="'tsid'" [(expandedDetailKeys)]="expandedDetailKeys"
    (dataStateChange)="dataStateChange($event)" (columnReorder)="onColReorder($event)"
    (columnResize)="onColResized($event)"
    [loading]="tsListLoading" class="grid" [rowClass]="getRowClass">
    <ng-template kendoGridNoRecordsTemplate>
        <div class="norecord">
            {{ isDataLoaded
            ? t7eNoRecord
            : t7eNotLoaded }}
        </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate *ngIf="isLargeScreen || debug">
        <span *ngIf="debug">{{ selectedTsids | json }}</span>
        <form class="toolbar">
            <div class="divider"></div>
            <button mat-raised-button color="primary" type="button" (click)="sendToProdMgrSelected()" 
                [disabled]="!selectedTsids.length" *ngIf="isFinance || isDev">
                <mat-icon>thumb_up</mat-icon>
                {{ 'set-at-prodmgr' | t7e:'Set: "At Prod Mgr"' }}
            </button>
            <button mat-raised-button color="primary" type="button" (click)="approveSelected()"
                [disabled]="!selectedTsids.length" *ngIf="isProdMgr || isDev">
                <mat-icon>thumb_up</mat-icon>
                {{ 'set-approved' | t7e:'Set: "Approved"' }}
            </button>
        </form>
    </ng-template>
    <kendo-grid-checkbox-column title="" [showSelectAll]="true" [width]="70" media="(min-width: 1601px)"></kendo-grid-checkbox-column>
    <kendo-grid-column field="entryon" title="entryon" [width]="170" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="conid" title="conid" [width]="170" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="usid" title="usid" [width]="470" *ngIf="debug">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem |json }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=usname [title]="'ts-grid.field.usname' | t7e:'Stábtag'" [width]="getWidth('usname') || 170"
        *ngIf="showUserName">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.email; else deletedUser">
                <p>{{ dataItem.usname }} </p>
                <p><a [href]="'mailto:'+dataItem.email" [title]="'send-email-title' | t7e:'Email küldés'" class="d-flex-align-items-center">
                    <mat-icon class="overflow-visible">mail_outline</mat-icon>
                    <span class="text-with-maticon">{{dataItem.email}}</span>
                </a></p>
            </ng-container>
            <ng-template #deletedUser>
                <span class="deleted-user">{{ 'deleted-user' | t7e: 'Törölt felhasználó' }}</span>
            </ng-template>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=depname [title]="'ts-grid.field.depname' | t7e:'Részleg'" [width]="getWidth('depname') || 170"></kendo-grid-column>
    <kendo-grid-column field=role [title]="'ts-grid.field.role' | t7e:'Munkakör'" [width]="getWidth('role') || 170">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="cell-with-link-only-on-hover">
                <div>{{dataItem.role}}</div>
                <div class="link-only-on-hover"><a [routerLink]="['/contract', dataItem.conid]">Start Form</a></div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=dDate [title]="'ts-grid.field.dDate' | t7e:'Dátum'" [width]="getWidth('dDate') || 170">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.startdate | date: dateFormat }}
            <!-- <app-editable-value class="editable-field" [field]="getEditable(dataItem, 'startdate', 'date')"
                (onSave)="saveField($event, dataItem, 'startdate')">
            </app-editable-value> -->
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=dStartDate [title]="'ts-grid.field.dStartDate' | t7e:'Idő'" [width]="getWidth('dStartDate') || 170">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="start-times">
                {{ dataItem.startdate | date: timeFormat }}
                <!-- <app-editable-value class="editable-field" [field]="getEditable(dataItem, 'startdate*2', 'time')"
                    (onSave)="saveField($event, dataItem, 'startdate*2')" readWidth="62px" editWidth="118px">
                </app-editable-value> -->
                <div class="crewcall-time" title="crew call" *ngIf="dataItem.shstartdate">
                    cc:&nbsp;{{ dataItem.shstartdate | date: timeFormat }}
                </div>
            </div>
            <div class="end-times">
                {{ dataItem.enddate | date: timeFormat }}
                <!-- <app-editable-value class="editable-field" [field]="getEditable(dataItem, 'enddate', 'time')"
                    (onSave)="saveField($event, dataItem, 'enddate')" readWidth="62px" editWidth="118px">
                </app-editable-value> -->
                <div class="camerawrap-time" title="camera wrap" *ngIf="dataItem.shenddate">
                    cw:&nbsp;{{ dataItem.shenddate | date: timeFormat }}
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=comment [title]="'ts-grid.field.comment' | t7e:'Megjegyzés'" [width]="getWidth('comment') || 170">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="tscount-warning" *ngIf="numPlannedDays.get(dataItem.usid) ">
                <mat-icon class="warning-icon" *ngIf="dataItem.tscount > numPlannedDays.get(dataItem.usid)">warning</mat-icon>
                {{ dataItem.tscount }}&nbsp;/&nbsp;{{ numPlannedDays.get(dataItem.usid) }}
                <span>{{ 'day' | t7e }}</span>
            </div>
            <div class="comment" *ngIf="dataItem.comment">{{ dataItem.comment }}</div>
            <div *ngIf="debug">{{ dataItem }}</div>
            <div class="extra-fee-request-warning" *ngIf="getExtraFeesSum(dataItem)">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    Extra díj: {{ getExtraFeesSum(dataItem) | number }}&nbsp;{{ dataItem.currency }}
                </span>
            </div>
            <div class="dayoptions-warning" *ngFor="let do of getDayoptionsName(dataItem.dayoptions)">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    {{ do }}
                </span>
            </div>
            <div class="zero-salary-warning" *ngIf="!dataItem.salary && !dataItem.calc_salary">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    {{ 'zero-salary-warning' | t7e:'Nincs megadva napidíj!' }}
                </span>
            </div>
            <div class="salary-mismatch" *ngIf="dataItem.salary && (dataItem.salary !== dataItem.calc_salary)">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    {{ 'salary-mismatch' | t7e:'Jóváhagyott és kalkulált napidíj különbözik!' }}
                </span>
            </div>
            <div class="wrong-location-warning"
                *ngIf="dataItem.distandpark && getLocation(dataItem.shlocpiid)?.locName && dataItem.distandparkcomment != getLocation(dataItem.shlocpiid)?.locName">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    Eltérő helyszín: "{{ dataItem.distandparkcomment }}" vs {{ getLocation(dataItem.shlocpiid)?.locName }}
                </span>
            </div>
            <div class="wrong-km-warning" *ngIf="dataItem.distandpark && getLocation(dataItem.shlocpiid)?.kmFee && dataItem.distandpark != getLocation(dataItem.shlocpiid)?.kmFee">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    Eltérő km díj: {{ dataItem.distandpark }} vs {{ getLocation(dataItem.shlocpiid)?.kmFee }}&nbsp;{{ dataItem.currency }}
                </span>
            </div>
            <div class="wrong-location-currency-warning"
                *ngIf="iskmParkVignSuspicious(dataItem)">
                <mat-icon class="warning-icon">warning</mat-icon>
                <span class="warning-text">
                    Gyanús km, parkolás vagy matrica díj. Eltérő pénznem?
                </span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="tsid" title="tsid" [width]="170" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="conid" title="conid" [width]="170" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="modusid" title="modusid" [width]="170" *ngIf="debug"></kendo-grid-column>


    <kendo-grid-command-column title="" [width]="230">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex-space-between d-flex-align-items-center">
                <app-status [statusName]="getStatusName(dataItem.tsstatus)"
                    [statusClass]="getStatusClass(dataItem)"
                    [saveComment]="dataItem?.savecomment">
                </app-status>
                <button mat-icon-button [matMenuTriggerFor]="statusMenu" type="button"
                    aria-label="More options">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #statusMenu="matMenu">
                    <button  mat-menu-item [routerLink]="['/', 'ts', dataItem.tsid]" *ngIf="isCrew">
                        <mat-icon>edit</mat-icon>
                        {{ 'edit' | t7e:'Szerkesztés' }}
                    </button>
                    <button  mat-menu-item (click)="sendBackToCrew(dataItem)" [disabled]="canSendBackToCrew(dataItem).disabled"
                        [title]="canSendBackToCrew(dataItem).title" *ngIf="isProdMgr">
                        <mat-icon>thumb_down</mat-icon>
                        {{ 'return-to-crew' | t7e:'Visszaküldés stábtagnak' }}
                    </button>
                    <button  mat-menu-item (click)="sendBackToProdMgr(dataItem)"
                        [disabled]="canRetractApproval(dataItem).disabled"
                        [title]="canRetractApproval(dataItem).title"
                        *ngIf="isFinance">
                        <mat-icon>reply_all</mat-icon>
                        {{ 'return-to-prodmgr' | t7e:'Visszaküldés gyártásvezetőnek' }}
                    </button>
                    <button  mat-menu-item (click)="sendBackToProdMgr(dataItem)"
                        [disabled]="canRetractApproval(dataItem).disabled"
                        [title]="canRetractApproval(dataItem).title"
                        *ngIf="isProdMgr">
                        <mat-icon>reply_all</mat-icon>
                        {{ 'retract-approval' | t7e:'Jóváhagyás visszavonása' }}
                    </button>
                    <button mat-menu-item (click)="approve(dataItem)"
                        [disabled]="canApprove(dataItem).disabled"
                        [title]="canApprove(dataItem).title"
                        *ngIf="isFinance">
                        <mat-icon>reply</mat-icon>
                        {{ 'return-to-approved' | t7e:'Vissza jóváhagyva státuszba' }}
                    </button>
                    <button  mat-menu-item (click)="removeTs(dataItem)" [disabled]="canRemoveTs(dataItem).disabled"
                        [title]="canRemoveTs(dataItem).title"  *ngIf="isProdMgr">
                        <mat-icon>delete</mat-icon>
                        {{ 'delete' | t7e:'Törlés' }}
                    </button>
                    <button mat-menu-item (click)="sendToProdMgr(dataItem)" *ngIf="isFinance && isDisabled(dataItem)">
                        <mat-icon>send</mat-icon>
                        {{ 'send-to-prodmgr' | t7e:'Beküldés gyártásvezetőnek' }}
                    </button>
                    <button mat-menu-item (click)="sendToProdMgr(dataItem)" *ngIf="isDev">
                        <mat-icon>send</mat-icon>
                        {{ 'send-to-prodmgr' | t7e:'Beküldés gyártásvezetőnek' }}
                    </button>
                </mat-menu>
            </div>
            <div class="buttons">
                <button mat-raised-button (click)="approve(dataItem)"
                    [title]="canApprove(dataItem).title"
                    *ngIf="!canApprove(dataItem).disabled" type="button">
                    <mat-icon>thumb_up</mat-icon>
                    {{ 'approve' | t7e:'Jóváhagyás' }}
                </button>
                <button mat-raised-button (click)="setPdfSent(dataItem)" type="button"
                    [title]="canSetPdfSent(dataItem).title" *ngIf="!canSetPdfSent(dataItem).disabled">
                    <mat-icon>call_made</mat-icon>
                    {{ 'set-sent' | t7e:'Szla mell. kiküldve' }}
                </button>
                <button mat-raised-button (click)="setInvoiceAccepted(dataItem)" type="button"
                    [title]="canSetInvoiceAccepted(dataItem).title"  *ngIf="!canSetInvoiceAccepted(dataItem).disabled">
                    <mat-icon>call_received</mat-icon>
                    {{ 'set-invoice-accepted' | t7e:'Szla befogadva' }}
                </button>
            </div>
        </ng-template>
    </kendo-grid-command-column>

    <div *kendoGridDetailTemplate="let dataItem" class="row-details">
    <section [title]="'daily-rate' | t7e:'Napidíj'">
        <div class="detail-box-title">
            {{ 'daily-rate' | t7e:'Napidíj' }}
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="salaryReason" showOn="click"
                (click)="setForReasons(dataItem.tsid, 'salary')">
                <mat-icon>help</mat-icon>
            </button>
        </div>
        <p class="calculated-value" [title]="'calculated-title' | t7e:'A bevitt adatok alapján számított napidíj'">
            {{ 'calculated' | t7e:'Kalkulált:' }}
            {{dataItem.calc_salary || 0 | number }} {{ dataItem.currency }}
        </p>
        <p class="overwritten-value" [title]="'overriden-daily-rate-title' | t7e:'Amit a gyártásvezető megadott'"
             [class.unimportant]="!dataItem.salary">
            <span>{{ 'saved' | t7e:'Jóváhagyott' }}:&nbsp;</span>
            <span *ngIf="dataItem.salary !== null; else noValue">
                {{ dataItem.salary || 0 | number:'1.0-2' }} {{ dataItem.currency }}
            </span>
        </p>
    </section>
    <section title="OT">
        <div class="detail-box-title">
            OT
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="salaryReason" showOn="click"
                (click)="setForReasons(dataItem.tsid, 'ot')">
                <mat-icon>help</mat-icon>
            </button>
        </div>
        <p class="calculated-value" [class.unimportant]="!dataItem.calc_overtimefee">
            {{ 'calculated' | t7e:'Kalkulált:' }}
            {{ dataItem.calc_othours || 0 | number:'1.0-2' }}&nbsp;óra / 
            {{ dataItem.calc_overtimefee || 0 | number }}&nbsp;{{ dataItem.currency }}
        </p>
        <p class="overwritten-value" [class.unimportant]="!dataItem.overtimefee">
            <span>{{ 'saved' | t7e:'Jóváhagyott' }}:&nbsp;</span>
            <span *ngIf="dataItem.overtimefee !== null; else noValue">
                {{ dataItem.overtimefee || 0 | number }}&nbsp;{{ dataItem.currency }}
            </span>
        </p>
    </section>
    <section title="TA">
        <div class="detail-box-title">
            TA
            <button type="button" mat-icon-button kendoPopoverAnchor [popover]="salaryReason" showOn="click"
                (click)="setForReasons(dataItem.tsid, 'ta')">
                <mat-icon>help</mat-icon>
            </button>
        </div>
        <p class="calculated-value" [class.unimportant]="!dataItem.calc_tafee">
            {{ 'calculated' | t7e:'Kalkulált:' }}
            {{ dataItem.calc_tahours || 0 | number:'1.0-2' }}&nbsp;óra /
            {{ dataItem.calc_tafee || 0 | number }}&nbsp;{{ dataItem.currency }}
        </p>
        <p class="overwritten-value" [class.unimportant]="!dataItem.tafee">
            <span>{{ 'saved' | t7e:'Jóváhagyott' }}:&nbsp;</span>
            <span *ngIf="dataItem.tafee !== null; else noValue">
                {{ dataItem.tafee || 0 | number }}&nbsp;{{ dataItem.currency }}
            </span>
        </p>
    </section>
    <section title="MP" *ngIf="MEAL_PENALTY === 'setAtProdDeptSf'">
        <div class="detail-box-title">
            MP
        </div>
        <p class="calculated-value" [class.unimportant]="!dataItem.f_mealPenaltyHours && !getMealPenalty_setAtProdDeptSf(dataItem)">
            {{ dataItem.f_mealPenaltyHours || 0 | number:'1.0-2' }}&nbsp;óra&nbsp;*
            {{ getMealPenalty_setAtProdDeptSf(dataItem) }}&nbsp;{{ dataItem.currency }}&nbsp;=
        </p>
        <p class="overwritten-value" [class.unimportant]="!dataItem.f_mealPenalty">
            {{ dataItem.f_mealPenalty || 0 | number:'1.0-2' }}&nbsp;{{ dataItem.currency }}
        </p>
    </section>
    <section [title]="'km-fee-title' | t7e:'km díj'">
        <div class="detail-box-title" t7e="km-fee">Km díj</div>
        <p *ngIf="dataItem.shlocpiid" class="dispo-location" title="dispo location">
            dispo: {{ getLocation(dataItem.shlocpiid)?.locName || 'n/a location' }} 
            {{ getLocation(dataItem.shlocpiid)?.km || 'n/a' }}&nbsp;km&nbsp;/&nbsp;{{ getLocation(dataItem.shlocpiid)?.kmFee || 'n/a' }}&nbsp;HUF
        </p>
        <p *ngIf="dataItem.distandparkcomment">{{ dataItem.distandparkcomment }}</p>
        <p *ngIf="dataItem.distandpark; else noValue" [class.unimportant]="!dataItem.distandpark">
            <!-- <app-editable-value [field]="getEditable(dataItem, 'distandpark', 'number')" [suffix]="dataItem.currency"
                (onSave)="saveField($event, dataItem, 'distandpark')">
            </app-editable-value> -->
            {{ dataItem.distandpark }} {{ dataItem.currency }}
        </p>
    </section>
    <section [title]="'pariking-fee-title' | t7e:'Parkolás díj'">
        <div class="detail-box-title" t7e="parking">Parkolás</div>
        <p *ngIf="dataItem.parkcomment">{{ dataItem.parkcomment }}</p>
        <p *ngIf="dataItem.park; else noValue" [class.unimportant]="!dataItem.park">
            <!-- <app-editable-value [field]="getEditable(dataItem, 'park', 'number')" [suffix]="dataItem.currency"
                (onSave)="saveField($event, dataItem, 'park')">
            </app-editable-value> -->
            {{ dataItem.park }} {{ dataItem.currency }}
        </p>
    </section>
    <section [title]="'vign-fee-title' | t7e:'Matrica'">
        <div class="detail-box-title" t7e="vignette">Matrica</div>
        <p *ngIf="dataItem.vignettecomment">{{ dataItem.vignettecomment }}</p>
        <p *ngIf="dataItem.vignette; else noValue" [class.unimportant]="!dataItem.vignette">
            <!-- <app-editable-value [field]="getEditable(dataItem, 'vignette', 'number')" [suffix]="dataItem.currency"
                (onSave)="saveField($event, dataItem, 'vignette')">
            </app-editable-value> -->
            {{ dataItem.vignette }} {{ dataItem.currency }}
        </p>
    </section>
    <section [title]="'other-fee-title' | t7e:'Other Allowance'">
        <div class="detail-box-title" t7e="otherfee">Egyéb díj</div>
        <p *ngIf="dataItem.otherfee; else noValue">{{ dataItem.otherfee | number }} {{ dataItem.currency }}</p>
    </section>
    <section [title]="'daily-rental-fees-title' | t7e:'Napi bérleti díjak'" *ngIf="dataItem.f_dailybasedrentalrate1 || dataItem.f_dailybasedrentalrate2 || dataItem.f_dailybasedrentalrate3 || dataItem.f_dailybasedrentalrate4 || dataItem.f_dailybasedrentalrate5 || dataItem.f_dailybasedrentalrate6">
        <div class="detail-box-title" t7e="daily-rental-fees">Napi bérleti díjak</div>
        <ng-container *ngFor="let fieldName of ['f_dailybasedrentalrate1', 'f_dailybasedrentalrate2', 'f_dailybasedrentalrate3', 'f_dailybasedrentalrate4', 'f_dailybasedrentalrate5', 'f_dailybasedrentalrate6', ]; trackBy:trackByIndex">
            <p *ngIf="dataItem[fieldName]">
                {{ getRentalNames(dataItem)[fieldName] }}:&nbsp;
                {{ dataItem[fieldName] | number }} {{ dataItem.currency }}
            </p>        
        </ng-container>
    </section>
    <section [title]="'pd-etc-title' | t7e:'Per Diem / Utazó nap / Fél nap / Pihenőnap'">
        <div class="detail-box-title" t7e="pd-travel-halfday-questionmark">Per Diem / Utazó nap / Fél nap / Pihenőnap?</div>
        <div *ngIf="debug">{{ dataItem.dayoptions | json }}</div>
        <p *ngFor="let do of getDayoptionsName(dataItem.dayoptions)" class="dayoptions">
            &check; {{ do }} <span *ngIf="do=='Meal Penalty'"> {{ getMealPenaltyFee_FirstValuableOtRate(dataItem) }} {{ dataItem.currency}}</span>
        </p>
    </section>
    <ng-template #noValue>
        <span class="unimportant">-</span>
    </ng-template>
</div>
</kendo-grid>

<app-save-comment *ngIf="showSaveComment" [questionText]="showSaveComment.title"
    [isCommentOptional]="showSaveComment.isCommentOptional" (saveClicked)="saveCommentReceived($event)"
    (cancelClicked)="showSaveComment = null">
</app-save-comment>

<kendo-popover #salaryReason>
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-salaryreason title" t7e="pop-salary-reason-title">
            Hogy jött ki?
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate="">
        <div class="pop-salaryreason body">
            <app-salary-reasons [tsid]="selectedSalaryReason.tsid" 
                [showSalary]="selectedSalaryReason.showSalary"
                [showOt]="selectedSalaryReason.showOt"
                [showTa]="selectedSalaryReason.showTa"
                *ngIf="selectedSalaryReason"></app-salary-reasons>
        </div>
    </ng-template>
</kendo-popover>