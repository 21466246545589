<mat-tab-group dynamicHeight [selectedIndex]="selectedMode"  (selectedTabChange)="selectedModeChanged($event)"
    mat-stretch-tabs>
    <mat-tab [label]="t7eTabLabelTableView">
            <form (submit)="reload()" [formGroup]="form">
                <div class="controls ml-05">
                    <app-filter-date-range [formgroup]="form" [fromFC]="fromFC" [toFC]="toFC" 
                        [min]="minDate" [max]="maxDate" [canReset]="true" class="form-field">
                    </app-filter-date-range>
                    <app-filter-user [formgroup]="form" [userFC]="userFC" class="form-field"></app-filter-user>
                    <app-filter-dept [formgroup]="form" [deptFC]="deptFC" class="form-field"></app-filter-dept>
                    <app-filter-workhours [formgroup]="form" [workhoursFC]="workhoursFC" class="form-field"></app-filter-workhours>
                    <app-filter-tsstatus [formgroup]="form" [statusFC]="statusFC" [style]="{width: '37rem'}" class="form-field"></app-filter-tsstatus>
                </div>
                <div class="buttons form-field">
                    <button mat-raised-button color="primary" type="submit">
                        {{ isDataLoaded ? t7eReload: t7eLoad}}</button>
                </div>
            </form>
            <app-timesheets [tsList$]="tsListFiltered$" [tsListLoading]="tsListLoading" [sizeChanged$]="gridSizeChanged$" [groupable]="isLargeScreen"></app-timesheets>
    </mat-tab>

    <mat-tab [label]="t7eTabLabelPageView">
        <ng-template matTabContent>            
            <mat-tab-group dynamicHeight *ngIf="(depWithPendingTs$ | async)?.length; else noTs"
            [selectedIndex]="selectedDeptTab" (selectedTabChange)="selectedDeptChanged($event)">
                <mat-tab [label]="dep.depname || 'n/a'"
                    *ngFor="let dep of (depWithPendingTs$ | async); trackBy:trackByDepid">

                    <ng-template matTabContent>
                    <mat-tab-group dynamicHeight *ngIf="(contractsByDepartmentWithPendingTs$[dep.depid!] | async)?.length; else noTs"
                        [selectedIndex]="selectedContractTab"  (selectedTabChange)="selectedContractChanged($event)">
                        <mat-tab [label]="getContractLabel(con)"
                            *ngFor="let con of (contractsByDepartmentWithPendingTs$[dep.depid!] | async); trackBy:trackByConid">

                            <ng-template matTabContent>
                            <mat-tab-group dynamicHeight *ngIf="(unapprovedTsOfContract$[con.conid!] | async)?.length; else noTs"
                                [selectedIndex]="selectedTsTab"  (selectedTabChange)="selectedTsChanged($event)">
                                <mat-tab [label]="ts | getTsDate"
                                    *ngFor="let ts of (unapprovedTsOfContract$[con.conid!] | async); trackBy:trackByTsId">
                                    <ng-template matTabContent>
                                    <div *ngIf="debug">
                                        <p>selectedContractTab: {{ selectedContractTab }}</p>
                                        <p>selectedTsTab: {{ selectedTsTab }}</p>
                                        <p>selectedTs: {{ ts | json }}</p>
                                    </div>
                                    <div class="ts-tab">
                                        <div (click)="nextTab(-1)" class="prev-next">
                                            <mat-icon class="prev-next-icon">keyboard_arrow_left</mat-icon>
                                            <!-- <p>{{ nextTab(-1, false) }}</p> -->
                                        </div>

                                        <app-edit-timesheet [timesheet]="ts" [layout]="'grid-form'" [showContract]="false"
                                            [showStatus]="false" class="">
                                        </app-edit-timesheet>

                                        <app-refresh-button (refresh)="reloadWaitingForProdMgr()"></app-refresh-button>

                                        <div (click)="nextTab()" class="prev-next">
                                            <mat-icon class="prev-next-icon">keyboard_arrow_right</mat-icon>
                                            <!-- <p>{{ nextTab(1, false) }}</p> -->
                                        </div>
                                    </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <ng-template #noTs>
                <div class="norecord" *ngIf="!tsListLoading; else tsLoading">
                    <mat-icon class="all-ts-approved">check</mat-icon>
                    {{ 'all-ts-approved'|t7e:'All timesheets are approved.' }}
                </div>
            </ng-template>

            <ng-template #tsLoading>
                <div class="loading">
                    <mat-spinner></mat-spinner>
                    {{ 'loading-timesheets'|t7e:'Loading timesheets...' }}
                </div>
            </ng-template>
        </ng-template>
    </mat-tab>

</mat-tab-group>