<form (submit)="reload()" [formGroup]="form">
    <div class="controls">
        <app-filter-date-range [formgroup]="form" [fromFC]="fromFC" [toFC]="toFC"
            [min]="minDate" [max]="maxDate"
            class="form-field">
        </app-filter-date-range>
        <app-filter-contract-type [formgroup]="form" [contractTypeFC]="contractTypeFC"
            class="form-field contract-type">
        </app-filter-contract-type>
        <mat-checkbox class="form-field mt-1" formControlName="hideSent">
            Kiküldöttek elrejtése
        </mat-checkbox>
        <mat-checkbox class="form-field mt-1" formControlName="hideInvoiceAccepted">
            Számlázottak elrejtése
        </mat-checkbox>


        <!-- <app-filter-contract [formgroup]="form" [contractFC]="contractFC" class="form-field"></app-filter-contract> -->
        <!-- <mat-form-field class="form-field group-by">
            <mat-label>One file per </mat-label>
            <mat-select placeholder="Placeholder" formControlName="groupBy" >
                <mat-option value="user">Crew member</mat-option>
                <mat-option value="role">Position (position)</mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
    <div class="buttons form-field">
        <button mat-raised-button color="primary" type="submit" [disabled]="canLoad().disabled">
            <mat-icon>refresh</mat-icon>
            {{ isDataLoaded ? 'Frissítés':'Betöltés'}}
        </button>
        <button mat-raised-button color="accent" type="button" [disabled]="canGenerate().disabled"
            (click)="generate(selectedTSs, false)" *ngIf="isFinance">
            <mat-icon>print</mat-icon>
            Generálás
        </button>
        <button mat-raised-button color="accent" type="button" [disabled]="canGenerate().disabled"
            (click)="generate(selectedTSs, true)" *ngIf="isFinance">
            <mat-icon>send</mat-icon>
            Generálás és kiküldés
        </button>
        <button mat-raised-button (click)="setPdfSent(selectedTSs)" [disabled]="canSetPdfSent(selectedTSs).disabled"
            [title]="canSetPdfSent(selectedTSs).title" *ngIf="isFinance" type="button">
            <mat-icon>check</mat-icon>
            Szla mell. kiküldve
        </button>
        <button mat-raised-button (click)="setInvoiceAccepted(selectedTSs)"
            [disabled]="canSetInvoiceAccepted(selectedTSs).disabled" [title]="canSetInvoiceAccepted(selectedTSs).title"
            *ngIf="isFinance" type="button">
            <mat-icon>done_all</mat-icon>
            Szla befogadva
        </button>
    </div>
</form>
<kendo-grid #grid [kendoGridBinding]="(gridData$ | async) || []"
    [pageable]="true" [pageSize]="50" [skip]="skip"
    [sortable]="true" [sort]="sort"
    [filterable]="true" [filter]="filter" (filterChange)="filterChanged($event)"
    [navigable]="true" [reorderable]="true" [resizable]="true"
    [scrollable]="'scrollable'" [loading]="(tsListLoading$ | async) || false" class="grid"
    [selectable]="{enabled: true, checkboxOnly: true, mode: 'multiple'}" [kendoGridSelectBy]="'conid'"
    [(selectedKeys)]="selectedContractIds"
    [kendoGridExpandDetailsBy]="'conid'" [(expandedDetailKeys)]="expandedDetailKeys"
    (dataStateChange)="dataStateChange($event)" (columnReorder)="onColReorder($event)"
    (columnResize)="onColResized($event)">
    <ng-template kendoGridNoRecordsTemplate>
        <div class="norecord">
            {{ isDataLoaded
            ? 'Nincs a szűrésnek megfelelő Timesheet'
            : 'Válassz szűrőket és kattints a Betöltés gombra'}}
        </div>
    </ng-template>

    <kendo-grid-checkbox-column title="" [showSelectAll]="true" [width]="70"></kendo-grid-checkbox-column>
    <kendo-grid-column field="usname" title="Stábtag" [width]="getWidth('usname') || 170">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="debug">ts ids: {{ dataItem.tsids | json }}<br>conid: {{ dataItem.conid }}</span>
            <span [title]="'ts ids: '+toJson(dataItem.tsids)+' és conid: '+dataItem.conid">
                {{ dataItem.usname }} <a [href]="'mailto:'+dataItem.email" title="Email küldés">(email)</a>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="departmentListForFilter" title="Department(s)" [width]="getWidth('departmentListForFilter') || 165">
        <ng-template kendoGridCellTemplate let-dataItem>
            <!-- {{ dataItem?.departmentListForFilter | json }} -->
            <div *ngFor="let dep of dataItem.departmentList" class="in-cell-list in-cell-list-departments">
                {{ dep }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="roleListForFilter" title="Position(s)" [width]="getWidth('roleListForFilter') || 165">
        <ng-template kendoGridCellTemplate let-dataItem>
            <!-- {{ dataItem?.departmentListForFilter | json }} -->
            <div *ngFor="let role of dataItem.roleList" class="in-cell-list in-cell-list-roles">
                {{ role }}
            </div>
            <!-- <div [style]="numSF(dataItem)>1 ? 'color:red' : ''">{{ numSF(dataItem) }}</div> -->
            <div *ngIf="hasMultipleStartForms(dataItem)">
                <app-status [statusName]="'Több start formra érkezett timesheet'" [statusClass]="'warning'">
                </app-status>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="companyListForFilter" title="Company" [width]="getWidth('companyListForFilter') || 165">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngFor="let comp of dataItem.companyList" class="in-cell-list in-cell-list-companies">
                {{ comp }}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="tstatus" title="Státusz" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p *ngIf="dataItem.isGenerating">
                <app-status [statusName]="'WTS generálása folyamatban'" [statusClass]="'warning'">
                </app-status>
            </p>
            <p [title]="'Azt jelöli, hogy ebben az időszakban az összes timesheet jóvá van-e hagyva'">
                <app-status [statusName]="getStatus(dataItem).isApproved"
                    [statusClass]="getStatus(dataItem).approvedClass">
                </app-status>
            </p>
            <p *ngIf="dataItem.isInvAccepted!=='yes'" [title]="'Azt jelöli, hogy ebben az időszakban az összes timesheetre be lett fogadva számla'">
                <app-status [statusName]="getStatus(dataItem).isSent"
                    [statusClass]="getStatus(dataItem).invoicedClass">
                </app-status>
            </p>
            <p *ngIf="dataItem.isSent!=='no'" [title]="'Azt jelöli, hogy ebben az időszakban az összes timesheetre ki lett-e küldve számlamelléklet (WTS)'">
                <app-status [statusName]="getStatus(dataItem).isInvAccepted" 
                    [statusClass]="getStatus(dataItem).invAcceptedClass">
                </app-status>
            </p>
            <p *ngIf="(lastWtsDayByUsid$ | async)?.[dataItem.usid]?.date; else noLastWtsDay" [title]="'Melyik a legutolsó időszak, amelyre már ki lett küldve számlamelléklet (WTS)'">
                <app-status [statusName]="(lastWtsDayByUsid$ | async)?.[dataItem.usid]?.statusText!" 
                [statusClass]="(lastWtsDayByUsid$ | async)?.[dataItem.usid]?.className!">
                </app-status>
            </p>
            <ng-template #noLastWtsDay>
                <app-status [statusName]="'Nincs utolsó rögzített WTS kiküldés'" 
                    [statusClass]="'warning'">
                </app-status>
            </ng-template>
            <!-- usid={{dataItem.usid}}; prevInv={{ (isPrevPeriodInvoiced$ | async)?.get(dataItem.usid) | json }} -->
            <p *ngIf="(isPrevPeriodInvoiced$ | async)?.get(dataItem.usid)?.statusName !== 'loading'; else prevPeriodInvoicesLoading"
                [title]="'Ez azt jelöli, hogy a MEGELŐZŐ időszakban minden számla be lett-e fogadva'">
                <app-status [statusName]="(isPrevPeriodInvoiced$ | async)?.get(dataItem.usid)?.statusName + prevPeriodEndDateText" 
                    [statusClass]="(isPrevPeriodInvoiced$ | async)?.get(dataItem.usid)?.statusClass">
                </app-status>
            </p>
            <ng-template #prevPeriodInvoicesLoading>
                <p class="d-flex gap-05">
                    <mat-spinner diameter="20" class="d-inline"></mat-spinner>
                    Előző számlák...
                </p>
            </ng-template>
            <p *ngIf="dataItem.isCurrencyMismatch">
                <app-status [statusName]="'Kevert pénznemek'" [statusClass]="'danger'"></app-status>
            </p>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="link" title="Számlamellékletek Google Drive-on" [width]="getWidth('link') || 400">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="debug">{{ dataItem | json }}</div>
            <app-gen-ts-file [dataItem]="dataItem" (draftGenerated)="draftGenerated($event)"
                [generationButtonStatus$]="generationButtonStatus$"></app-gen-ts-file>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" [width]="220" *ngIf="isFinance">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="row-buttons d-flex flex-wrap gap-1">
                <button mat-raised-button (click)="setPdfSent(dataItem)" [disabled]="canSetPdfSent(dataItem).disabled"
                    [title]="canSetPdfSent(dataItem.tsList).title" type="button">
                    <mat-icon>call_made</mat-icon>
                    Szla mell. kiküldve
                </button>
                <button mat-raised-button (click)="setInvoiceAccepted(dataItem)"
                    [disabled]="canSetInvoiceAccepted(dataItem.tsList).disabled" [title]="canSetInvoiceAccepted(dataItem).title">
                    <mat-icon>call_received</mat-icon>
                    Szla befogadva
                </button>
            </div>
        </ng-template>
    </kendo-grid-command-column>

    <div *kendoGridDetailTemplate="let dataItem">
        <!-- {{ dataItem.usid }} -->
        <app-timesheets *ngIf="isGroupByRole" [tsList$]="aTsList$ForConid[dataItem.conid]" 
            [groupable]="false" [showUserName]="false" [useSavedFilters]="false"></app-timesheets>
        <app-timesheets *ngIf="isGroupByUser" [tsList$]="aTsList$ForUsid[dataItem.usid]" 
            [groupable]="false" [showUserName]="false" [useSavedFilters]="false"></app-timesheets>
    </div>
</kendo-grid>

<div [title]="'inv-attachment' | t7e:'Számlakép'"
    *ngIf="draft$ | async" (close)="closeDraft()" class="draft-window">
    <div class="draft-window-title-bar">
        <span class="title">{{ 'inv-attachment' | t7e:'Számlakép' }}</span>
        <button mat-icon-button (click)="closeDraft()" class="draft-window-close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div [innerHTML]="(draft$ | async)" class="draft-window-content"></div>
</div>